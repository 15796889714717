.footer {
    background-color: #030606;
    color: #7b6e4c;
    padding: 20px;
    text-align: center;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .footer-brand {
    font-size: 20px;
    font-family: 'Libre Baskerville', serif;
  }
  
  .footer-social {
    display: flex;
    align-items: center;
  }
  
  .social-icon {
    font-size: 24px;
    margin-left: 10px;
    color: #7b6e4c;
    ;
  }
  
  @media (max-width: 768px) {
    .footer {
      padding: 10px;
    }
  
    .footer-brand {
      font-size: 18px;
    }
  
    .social-icon {
      font-size: 20px;
    }
  }
  