.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #030606;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar-brand {
  font-weight: bold;
  font-size: 24px;
  color: #7b6e4c;
  font-family: "Libre Baskerville", serif;
}

.navbar-menu-icon {
  display: none;
  cursor: pointer;
}

.navbar-menu-icon .icon-bar {
  width: 25px;
  height: 3px;
  background-color: #7b6e4c;
  margin-bottom: 4px;
  transition: transform 0.2s, opacity 0.2s;
}

.open .icon-bar:nth-child(1) {
  transform: rotate(-45deg) translate(-4px, 6px);
}

.open .icon-bar:nth-child(2) {
  opacity: 0;
}

.open .icon-bar:nth-child(3) {
  transform: rotate(45deg) translate(-4px, -6px);
}

.navbar-list {
  list-style: none;
  display: flex;
  align-items: center;
}

.navbar-item {
  margin-left: 20px;
  font-size: 16px;
  color: #7b6e4c;
  transition: color 0.2s;
  cursor: pointer;
}

.navbar-item:hover {
  color: #555;
}

@media (max-width: 768px) {
  .navbar {
    padding: 10px;
  }

  .navbar-menu-icon {
    display: block;
  }

  .navbar-list {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px;
    width: 100%;
  }

  .open .navbar-list {
    display: flex;
    flex-direction: column;
  }

  .navbar-item {
    margin: 10px 0;
    width: 100%;
  }
}
