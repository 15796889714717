/* Global styles */
body {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  margin: 0;
  padding: 0;
}

/* Product container */
.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #7b6e4c;
  padding: 20px;
  border-top: 10px solid black;
  text-align: center;
  /* margin: 20px; */
  /* border-radius: 10px; */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Product image container */
.product-image-container {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
}


.button-container {

  display: flex;
  gap: 0.5rem;
}
/* Product image */
.product-image {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
  border-radius: 10px;
}

/* Product info */
.product-info {
  margin: 20px 0;
  text-align: center;
}

/* Product title */
.product-title {
  font-size: 24px;
  margin-bottom: 10px;
}

/* Product description */
.product-description {
  font-size: 16px;
  margin-bottom: 20px;
}

/* Product details */
.product-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* Product detail */
.product-detail {
  flex-basis: 50%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

/* Product detail label */
.product-detail-label {
  font-weight: bold;
  margin-right: 5px;
}

/* Product detail value */
.product-detail-value {
  font-style: italic;
}

/* Modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Modal content */
.modal-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 90%;
  max-height: 90%;
}

/* Modal image */
.modal-image {
  max-width: 60%;
  /* max-height: 100%; */
  object-fit: contain;
}

/* Modal close button */
.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: rgb(185, 30, 30);
  cursor: pointer;
}

/* Previous and Next Buttons */
.prev-button,
.next-button {
  display: block;
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100px;
  transition: background-color 0.3s ease;
}

.prev-button:hover,
.next-button:hover {
  background-color: #45a049;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .product {
    padding: 10px;
    margin: 10px;
  }

  .product-title {
    font-size: 20px;
  }

  .product-description {
    font-size: 14px;
  }

  .modal-content {
    max-width: 100%;
    max-height: 100%;
  }
}
