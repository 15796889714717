.header {
    background-image: url("../assets/douro1.jpg"); /* Update the path to your image file */
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 40px;
    text-align: center;
    height: 500px;
    color: #030606;
  }
  
  .header-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .header-text {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
    font-family: 'Libre Baskerville', serif;

  }
  
  .header-description {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  @media (max-width: 768px) {
    .header {
      padding: 20px;
    }
  
    .header-text {
      font-size: 24px;
    }
  
    .header-description {
      font-size: 14px;
    }
  }
  